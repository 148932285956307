import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';

import Logo from './assets/ParishNavigator-Logo.png';
import HeroImg from './assets/ParishNavigator-Screen.png';
import PopeFrancis from './assets/pope-francis.jpg';

// function App() {
//   return (
//     <div className="App">
//       <nav class="navbar navbar-expand-lg bg-white rounded-4 shadow">
//         <div class="container-fluid">
//           <a className="navbar-brand d-flex" href="#">
//               <img src={Logo} alt="Logo" height="48" className="d-inline-block align-text-center me-1" />
//               <b className="h-0 my-auto">ParishNavigator</b>
//             </a>


//           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//             <span class="navbar-toggler-icon"></span>
//           </button>
//           <div class="collapse navbar-collapse" id="navbarNav">
//             <ul class="navbar-nav ms-auto">
//               <li class="nav-item">
//                 <a class="nav-link active" aria-current="page" href="#">Home</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link" href="#">Features</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link" href="#">Pricing</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link disabled">Disabled</a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>

//       <div className="bg-white hero-section">
//         <div className="row h-100">
//           <div className="col-md mt-5 d-flex flex-column justify-content-center">
//             <div className="text-start ps-5">
//               <h1 className="animate__animated animate__fadeInDown">Transform your Catholic Parish Management</h1>
//               <p className="animate__animated animate__fadeInUp">Empowering Catholic Parishes for Efficient and Purposeful Administration</p>
//             </div>
//           </div>
          
//           <div className="col-md d-flex w-100 align-items-start align-items-md-center">
//             <div className="heroImgSize mx-5 d-flex bg-primary shadow rounded-5 animate__animated animate__slideInRight" style={{backgroundImage: `url(${HeroImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'bottom'}}></div>
//           </div>
//         </div>
//       </div>

//       <section id="key-features" className="py-5">
//       <div className="container">
//         <h2 className="text-center mb-5">Key Features</h2>
//         <div className="row">
//           <div className="col-md-4">
//             <div className="card h-100">
//               <div className="card-body">
//                 <h5 className="card-title">Manage Parish Members</h5>
//                 <p className="card-text">The Parish Management Software allows seamless organization and administration of parish members. This feature enables the comprehensive management of member profiles, including personal details, contact information, membership status, and participation in parish activities. With an easy-to-use interface, administrators can efficiently update and maintain accurate records of the parish community, fostering stronger connections and effective communication.</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="card h-100">
//               <div className="card-body">
//                 <h5 className="card-title">Manage Sacraments</h5>
//                 <p className="card-text">This software simplifies the process of managing sacraments within the parish. From baptism and confirmation to marriage and other sacraments, the system streamlines the documentation, scheduling, and coordination involved in these sacred events. Parish administrators can easily track sacramental records, schedule ceremonies, and generate reports, ensuring that the sacramental life of the parish is organized and accessible.</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="card h-100">
//               <div className="card-body">
//                 <h5 className="card-title">Everything in One Place</h5>
//                 <p className="card-text">One of the standout features of this parish management software is its ability to centralize all parish-related information in a single, cohesive platform. From forms and contact information to sacramental records, everything converges into one accessible space. This unified approach enhances efficiency by eliminating the need for disparate systems. Whether it's managing parishioner data, handling inquiries through contact forms, or overseeing sacramental processes, administrators can seamlessly respond and communicate, fostering a more integrated and connected parish community. The convenience of having all these elements in one place not only saves time but also promotes effective collaboration among parish staff, clergy, and community members.</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>


//     <section id="prices" className="py-5 bg-light">
//       <div className="container">
//         <h2 className="text-center mb-5">Choose Your Plan</h2>
//         <div className="row">
//           <div className="col-md-6">
//             <div className="card text-center">
//               <div className="card-body">
//                 <h5 className="card-title">Monthly Plan</h5>
//                 <p className="card-text">$9.99/month</p>
//                 <a href="#" className="btn btn-primary">
//                   Get Started
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="card text-center">
//               <div className="card-body">
//                 <h5 className="card-title">Yearly Plan</h5>
//                 <p className="card-text">$99.99/year</p>
//                 <a href="#" className="btn btn-primary">
//                   Get Started
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>


//     <section id="find-out-more" className="py-5">
//       <div className="container">
//         <div className="card bg-dark text-white">
//           <img
//             src={HeroImg} // Replace with your background image URL
//             className="card-img"
//             alt="Discover More"
//             style={{opacity: "0.4"}}
//           />
//           <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center">
//             <h2 className="card-title display-4 mb-4">Unlock the Power of Parish Management</h2>
//             <p className="card-text lead mb-4">
//               Streamline your parish operations with our comprehensive management software designed
//               for the Catholic community.
//             </p>
//             <form className="w-75">
//               <div className="form-row mb-3">
//                 <div className="col">
//                   <input type="text" className="form-control" placeholder="Your Name" />
//                 </div>
//                 <div className="mt-3 col">
//                   <input type="text" className="form-control" placeholder="Parish Name" />
//                 </div>

//                 <div className="mt-3 col">
//                   <input type="email" className="form-control" placeholder="Email Address" />
//                 </div>
//               </div>
//               <div className="form-group mb-3">
//                 <input type="text" className="form-control" placeholder="Where is the Parish" />
//               </div>
//               <div className="form-group mb-3">
//                 <textarea className="form-control" rows="3" placeholder="Any Questions"></textarea>
//               </div>
//               <div className="d-flex">
//                 <button type="submit m" className="btn btn-light btn-lg">
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
    

    

//     </div>
//   );
// }

function App() {
  return (
    <div>
      {/* <!-- Navigation--> */}
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
          <div class="container px-5">
              <a class="navbar-brand" href="index.html">
                <img src={Logo} alt="Logo" height="24" class=" me-1 d-inline-block align-text-top" />
                ParishNavigator</a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li class="nav-item"><a class="nav-link" href="#contactForm">
                        <button className="btn btn-primary">Get in touch</button>
                        </a></li>
                  </ul>
              </div>
          </div>
      </nav>

      {/* <!-- Header--> */}
      <header class="bg-dark py-5">
          <div class="container px-5">
              <div class="row gx-5 align-items-center justify-content-center">
                  <div class="col-lg-8 col-xl-7 col-xxl-6">
                      <div class="my-5 text-center text-xl-start">
                          <h1 class="display-5 fw-bolder text-white mb-2">Transforming Parish Administration</h1>
                          <p class="lead fw-normal text-white-50 mb-4">Efficient, Intuitive, and Purpose-Driven Catholic Parish Management Software</p>
                          <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                              <a class="btn btn-primary btn-lg px-4 me-sm-3" href="#contactForm">Get Started</a>
                              <a class="btn btn-outline-light btn-lg px-4" href="#features">Learn More</a>
                          </div>
                      </div>
                  </div>
                  <div class=" d-none d-xl-block text-center"><img class="img-fluid rounded-3 my-5" src={HeroImg} alt="..." /></div>
              </div>
          </div>
      </header>

      {/* <!-- Features section--> */}
      <section class="py-5" id="features">
          <div class="container px-5 my-5">
              <div class="row gx-5">
                  <div class="col-lg-4 mb-5 mb-lg-0"><h2 class="fw-bolder mb-0">A better way to Manage your Parish.</h2></div>
                  <div class="col-lg-8">
                      <div class="row gx-5 row-cols-1 row-cols-md-2">
                          <div class="col mb-5 h-100">
                              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-collection"></i></div>
                              <h2 class="h5">Streamline Registration & Management</h2>
                              <p class="mb-0">Gone are the days of sifting through piles of paperwork. With our Parish Management Software, handling parishioner registrations and details becomes seamless and efficient. Through our intuitive online platform, parishioners can easily register and update their information, ensuring accurate records at your fingertips.</p>
                          </div>
                          <div class="col mb-5 h-100">
                              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-building"></i></div>
                              <h2 class="h5">Sacramental Form Management & Seamless Communication</h2>
                              <p class="mb-0">Effortlessly handle sacramental requests with our pre-built online forms, seamlessly tracked within the system. Once submitted, stay connected—use our platform to create a chat room, send emails, ensuring clear communication with those who've initiated sacramental processes. Simplify sacramental administration and strengthen connections with our integrated solution.</p>
                          </div>
                          <div class="col mb-5 mb-md-0 h-100">
                              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                              <h2 class="h5">Effortless Parish Organisation</h2>
                              <p class="mb-0">Empower parish organization with our user grouping feature, enabling seamless categorization and role assignment for parishioners. Easily create groups and assign roles, streamlining communication and clarifying responsibilities within your parish community. Parishioners can claim roles during signup, fostering a structured environment for efficient collaboration and easy contact within groups.</p>
                          </div>
                          <div class="col h-100">
                              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                              <h2 class="h5">Administration Collaboration</h2>
                              <p class="mb-0">Enhance teamwork with our multi-user accounts, enabling seamless collaboration. Work together efficiently by sharing to-do lists within a single account. Streamline parish tasks, fostering teamwork and coordinated efforts for a more connected and productive community.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      {/* <!-- Testimonial section--> */}
      <div class="py-5 bg-light">
          <div class="container px-5 my-5">
              <div class="row gx-5 justify-content-center">
                  <div class="col-lg-10 col-xl-7">
                      <div class="text-center">
                          <div class="fs-4 mb-4 fst-italic">"The parish is a community of communities, a sanctuary where the thirsty come to drink in the midst of their journey, and a center of constant missionary outreach"</div>
                          <div class="d-flex align-items-center justify-content-center">
                              <img class="rounded-circle me-3" src={PopeFrancis} alt="..." style={{height: "40px", width: "40px"}}/>
                              <div class="fw-bold">
                                  Pope Francis
                                  <span class="fw-bold text-primary mx-1">/</span>
                                  Vicar of Christ
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      {/* <!-- Call to action--> */}
      <aside class="bg-primary bg-gradient rounded-3 p-4 p-sm-5 mt-5">
          <div class="d-flex align-items-center justify-content-between flex-column text-center ">
              <div class="mb-4 ">
                  <div class="fs-3 fw-bold text-white">Fill out the Form the Learn More</div>
                  <div class="text-white-50">Simplifying Parish Management.</div>
              </div>
              <form class="ms-xl-4" id='contactForm' action="https://getform.io/f/cf53fa7a-90a7-4e63-a4ca-377be2d25b64" method="POST">
                  <div class="input-group mb-2">
                      
                      <div className="w-100">
                        <input class="form-control" type="text" placeholder="Your Name" name="Priest Name"/>
                        <input class="form-control mt-1" type="text" name="Parish Name" placeholder="Parish Name" />
                        <input class="form-control mt-1" type="email" name="Email" placeholder="Email Address" />
                        <button className="btn btn-success mt-1 w-100" type='submit'>Submit</button>
                      </div>
                  </div>
                  <div class="small text-white-50">We care about privacy, and will never share your data.</div>
              </form>
          </div>
      </aside>

      {/* <!-- Footer--> */}
        <footer class="bg-dark py-4 mt-auto">
            <div class="container px-5">
                <div class="row align-items-center justify-content-between flex-column flex-sm-row">
                    <div class="col-auto"><div class="small m-0 text-white">Copyright &copy; ParishNavigator</div></div>
                    {/* <div class="col-auto">
                        <a class="link-light small" href="#!">Privacy</a>
                        <span class="text-white mx-1">&middot;</span>
                        <a class="link-light small" href="#!">Terms</a>
                        <span class="text-white mx-1">&middot;</span>
                        <a class="link-light small" href="#!">Contact</a>
                    </div> */}
                </div>
            </div>
        </footer>
    </div>
  )
}

export default App;
